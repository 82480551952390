import CanvasMarker from "../CanvasMarker/CanvasMarker";
import { PixiMarker } from "../../libs/PixiMarker";
import { MarkerProps as LeafletMarkerProps, Popup, useMap } from "react-leaflet";
import { MapCategoryType, MapFeatureType } from "../../types/MapTypes";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MarkerPopup from "../MarkerPopup/MarkerPopup";
import useMarkerToggle from "../../hooks/useMarkerToggle";
import MarkerTooltip from "../MarkerTooltip/MarkerTooltip";
import React from "react";
import { ActiveMarkerState, ClickedMarkerState, MainSidebarTabIndex, SidePopupContentState, SidePopupModeState, SiteOptionsState } from "../../store";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { PopupEvent } from "leaflet";

interface MarkerProps extends LeafletMarkerProps {
	marker: MapFeatureType;
	category: MapCategoryType;
}

const Marker: React.FC<MarkerProps> = (props) => {
	const { position, marker, category } = props;
	const markerRef = useRef<typeof PixiMarker>(null);
	const [popupOpen, setPopupOpen] = useState(false);
	const { hiddenMarkers, toggleMarker } = useMarkerToggle();
	const isSidePopupMode = useRecoilValue(SidePopupModeState);
	const siteOptions = useRecoilValueLoadable(SiteOptionsState);
	const [popup, setPopup] = useRecoilState(SidePopupContentState);
	const setClickedMarker = useSetRecoilState(ClickedMarkerState);
	const setActiveTab = useSetRecoilState(MainSidebarTabIndex);
	const [activeMarker, setActiveMarker] = useRecoilState(ActiveMarkerState);

	const map = useMap();

	useEffect(() => {
		if (activeMarker === marker.properties.id) {
			map.flyTo(position, parseInt(marker.properties.link_zoom));
			setActiveMarker(null);
			let url = new URL(window.location.toString());
			url.searchParams.delete("marker_id");
			window.history.pushState({}, "", url.toString());
		}
	}, [activeMarker]);

	useEffect(() => {
		if (!markerRef.current) return;
		let m = markerRef.current;
		m.on("popupopen", handlePopupOpen);
		return () => {
			m.off("popupopen");
		};
	}, []);

	useEffect(() => {
		if (!markerRef.current) return;
		let op = markerRef.current.getOpacity();

		if (marker.properties.only_text === "1") {
			op !== 0 && markerRef.current.setOpacity(0);
			return;
		}

		if (hiddenMarkers.includes(marker.properties.id)) {
			if (op !== 0.5) markerRef.current.setOpacity(0.5);
		} else {
			if (op !== 1) markerRef.current.setOpacity(1);
		}
	}, [hiddenMarkers]);

	useEffect(() => {
		if (popupOpen) {
			markerRef.current.openPopup();
		}
	}, [popupOpen]);

	useEffect(() => {
		if (!isSidePopupMode && popup?.id === marker.properties.id) {
			setPopupOpen(true);
		} else {
			setPopupOpen(false);
		}
	}, [isSidePopupMode]);

	const handlePopupOpen = (e: PopupEvent) => {
		setClickedMarker({
			...marker.properties,
			category_image: category.icon_image,
		});
		setActiveTab(1);
		const { image, note_title, id, name, category_id } = marker.properties;
		setPopup({
			id,
			name,
			image,
			content: note_title,
			category_id,
			toggleMarker: handleToggleMarker,
		});

		if (isSidePopupMode || siteOptions.contents?.popup_mode === "on_side") {
			setPopupOpen(false);
			e.popup.remove();
		}

		setPopupOpen(true);
	};

	const handleToggleMarker = useCallback(() => {
		const { id, sibling_identifier } = marker.properties;
		toggleMarker(id, sibling_identifier);
	}, [hiddenMarkers, toggleMarker, marker.properties]);

	const icon = useMemo(() => {
		let iconSize = parseInt(marker.properties.icon_size);
		let iconSizeArray = [iconSize, iconSize] as [number, number];

		return {
			url: marker.properties.icon_image || category.icon_image,
			size: iconSizeArray,
		};
	}, [marker.properties, category.icon_image]);

	const handleClick = () => {};

	return (
		<CanvasMarker
			ref={markerRef}
			center={position}
			iconUrl={`${icon.url}`}
			// iconUrl={`https://interactivemap.app${icon.url}`}
			eventHandlers={{ click: handleClick }}
			size={icon.size}
		>
			{!isSidePopupMode && popupOpen ? (
				<MarkerPopup marker={marker} />
			) : (
				<Popup className="hidden" />
			)}

			{marker.properties.icon_text && <MarkerTooltip marker={marker} />}
		</CanvasMarker>
	);
};

export default React.memo(Marker);
